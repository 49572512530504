import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import { FC } from 'react'
import Image from 'next/image'

import styles from './loco-ai.module.scss'
import { Size } from '@/types/system'
import { selectIsAuthenticated } from '@/store/selectors/user'
import { ROUTES } from '@/lib/routes'
import { constants, STATUS_AI_INTRO } from '@/lib/constants'
import { getWithExpiry, storageAvailable } from '@/lib/utilities/system'

const AuthenticationModal = dynamic(() => import('@/components/modals/AuthenticationModal'), {
  ssr: false,
})
const Icon = dynamic(() => import('@/components/styleguide/Icon'))

export const LocoAi = () => {
  const router = useRouter()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const onCtaClick = () => {
    let storageKeyExists = false

    if (storageAvailable('localStorage')) {
      const storageKey = getWithExpiry(constants.localStorageKey.aiIntro)
      storageKeyExists = !!storageKey
    }

    if (isAuthenticated && storageKeyExists) {
      router.push(ROUTES.locoAiSearchPage.url)
    } else {
      NiceModal.show(AuthenticationModal as FC, {
        initialStatus: STATUS_AI_INTRO,
      }).then(() => {
        router.push(ROUTES.locoAiSearchPage.url)
      })
    }
  }

  return (
    <div className={styles.container}>
      <button type="button" className={styles.background} onClick={onCtaClick}>
        <div className={styles.content}>
          <p className={styles.small}>Powered by <span>LocoAI</span></p>
          <h2>AI-powered search meets car leasing</h2>
          <p>Not sure of the right car for you? AI-powered search makes finding your perfect car effortless.</p>
          <span className={styles.button}><Icon name="Sparkles" size={Size.Medium} />Try it</span>
        </div>
        <div className={styles.image}>
          <Image
            src={`${constants.imageHost.assets}/homepage/ai-foreground.png`}
            alt="Luis Loco"
            height={452}
            width={524}
          />
          <div className={styles.mask}>
            <Image
              src={`${constants.imageHost.assets}/homepage/luis-mask.png`}
              alt="Luis Loco"
              height={450}
              width={450}
            />
          </div>
        </div>
      </button>
    </div>
  )
}
